import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import React from "react";
import Layout from "../components/Layout";
import DotPointsWithIcons from "../components/shared/DotPointsWithIcons";
import FullScreenHero from "../components/shared/FullScreenHero";
import { styled } from "../stitches/stitches.config";

import PieChart from "../components/industry/assets/pie_chart.inline.svg";
import Additive from "../components/industry/assets/additive.inline.svg";
import Timer from "../components/industry/assets/timer.inline.svg";
import Squares from "../components/industry/assets/squares_b.inline.svg";
import TextButtonsImage from "../components/shared/TextButtonsImage";
import WorkWithUs from "../components/industry/WorkWithUs";
import Testimonial from "../components/shared/Testominial/Testimonial";
import KnowledgeCentrePreview from "../components/knowledgeCentre/KnowledgeCentrePreview";
import Newsletter from "../components/shared/Newsletter/Newsletter";

const seo = {
  title: "Industry ",
  description:
    "Plastometrex's indentation technologies make mechanical testing faster and more versatile than ever before. Explore how PIP testing can support your testing requirements whether carrying our high throughput testing, failiure analysis or new alloy development.",
};

const heroTitle = `We’re here to help you innovate and problem solve`;
const heroByline = `Enhance productivity, drive innovation, and develop new products`;
const heroSecondaryButton = {
  text: "Request a quote",
  path: "/request-a-quote",
};
const imageAlt =
  "The PLX-Benchtop, faster mechanical testing for industry";

const blockTitle = `Industry is modernising – are you?`;
const blockByline = `Our mechanical testing systems are being designed to make mechanical testing simpler, faster and more intuitive, providing our clients with material insights not otherwise obtainable. They are built intelligently, with integration and automation at the forefront of their development. They are Industry 4.0 ready.`;
const blockSecondaryButton = {
  text: "Request a technical specification",
  path: "/request-a-technical-specification",
};
const blockImageAlt = `The PLX-Benchtop, automated fast mechanical testing for industry 4.0`;

const testimonial = {
  name: "Matthew Hopkinson",
  position: "Executive Vice President  |  Element Materials Technology",
  description:
    "“This technology and this team have the potential to fundamentally change the way that we think about mechanical testing. Faster, simpler and more insightful testing will be appealing to all industries with an interest in measuring the strength of their materials”",
};

const additive = {
  title: "Additively Manufactured Materials",
  byline:
    "Measuring the mechanical properties of AM materials has historically been very difficult and very expensive. Indentation Plastometry is the simplest solution to this broad industry challenge.",
  secondaryButton: {
    text: "View case studies",
    path: "/case-studies",
  },
  imageAlt:
    "Plastometrex's PIP testing for additive manufacturing applications.",
};

const preview = {
  title: "Case Studies",
  byline:
    "At Plastometrex we are always exploring a wide range of applications for the PIP method. See below for our latest case studies.",
  linkPath: "/case-studies",
};

const points = [
  {
    icon: <PieChart />,
    title: "Failure Analysis",
    description:
      "Support your forensic investigations with uniquely rich datasets.",
  },
  {
    icon: <Squares />,
    title: "New Alloy Development",
    description:
      "Rapidly screen the mechanical properties of your new alloy systems",
  },
  {
    icon: <Timer />,
    title: "High Throughput Tests",
    description:
      "Measure stress-strain curves in under three minutes and reduce your materials testing turnaround times",
  },
  {
    icon: <Additive />,
    title: "Additive Manufacturing",
    description:
      "Characterise the mechanical behaviour of your additively manufactured materials",
  },
];

const IndustryPage = () => {
  const data = useStaticQuery(graphql`
    query {
      render: file(relativePath: { eq: "industry/industry_hero.png" }) {
        childImageSharp {
          gatsbyImageData(height: 600, placeholder: BLURRED)
        }
      }
      demo: file(relativePath: { eq: "plastometer_demo.png" }) {
        childImageSharp {
          gatsbyImageData(width: 850, placeholder: BLURRED)
        }
      }
      additive: file(relativePath: { eq: "additive.jpeg" }) {
        childImageSharp {
          gatsbyImageData(width: 850, placeholder: BLURRED)
        }
      }
      testimonial: file(
        relativePath: { eq: "testimonials/matthew_hopkinson.jpeg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 134, placeholder: BLURRED)
        }
      }
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/caseStudies/" } }
        sort: { fields: [frontmatter___date], order: DESC }
        limit: 3
      ) {
        edges {
          node {
            frontmatter {
              title
              date
              description
              pdf {
                publicURL
              }
              image {
                childImageSharp {
                  gatsbyImageData(width: 390)
                }
              }
            }
          }
        }
      }
    }
  `);

  const heroImage = getImage(data.render);
  const blockImage = getImage(data.demo);
  const additiveImage = getImage(data.additive);
  const testimonialImage = getImage(data.testimonial);
  return (
    <Layout seo={seo} backgroundColorUnderneath="dark">
      {heroImage && (
        <FullScreenHero
          title={heroTitle}
          byline={heroByline}
          secondaryButton={heroSecondaryButton}
          image={heroImage}
          imageAlt={imageAlt}
        />
      )}
      <DotPointsWithIcons points={points} />
      {blockImage && (
        <TextButtonsImage
          title={blockTitle}
          byline={blockByline}
          secondaryButton={blockSecondaryButton}
          image={blockImage}
          imageAlt={blockImageAlt}
        />
      )}
      <WorkWithUs />
      <Testimonial
        image={testimonialImage}
        name={testimonial.name}
        position={testimonial.position}
        description={testimonial.description}
      />
      {additiveImage && (
        <TextButtonsImage
          title={additive.title}
          byline={additive.byline}
          secondaryButton={additive.secondaryButton}
          image={additiveImage}
          imageAlt={additive.imageAlt}
          demoButton={false}
        />
      )}
      <KnowledgeCentrePreview headingDetails={preview} data={data} />
      <NewsletterWrapper>
        <Newsletter />
      </NewsletterWrapper>
    </Layout>
  );
};

export default IndustryPage;

const NewsletterWrapper = styled("div", { "@md": { paddingBottom: 100 } });

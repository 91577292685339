import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import {
  heading100,
  heading150,
  heading300,
  paragraphTextSmall,
} from "../../stitches/combinedStyles";
import { styled } from "../../stitches/stitches.config";

const title = `Why work with us?`;

const items = [
  {
    title: "Flexibility and Speed",
    description:
      "We use our products to help companies like yours measure the mechanical properties of their materials more quickly, and more flexibly than they can with conventional testing methods.",
  },
  {
    title: "First Class Support",
    description:
      "We provide a rapid and rigorous technical support service to customers across the world. We also offer R&D support services through access to our team of world-leading materials scientists.",
  },
  {
    title: "Experienced",
    description:
      "We are an expert team of materials scientists with high-level expertise in advanced numerical modelling and data science techniques. We can be relied upon to deliver to our clients the state-of-the-art mechanical testing solutions that are needed to maintain a competitive advantage.",
  },
];

const WorkWithUs = () => {
  const data = useStaticQuery(graphql`
    query {
      plastometer: file(relativePath: { eq: "plastometer_image.png" }) {
        childImageSharp {
          gatsbyImageData(width: 600, placeholder: BLURRED)
        }
      }
    }
  `);

  const image = getImage(data.plastometer);

  return (
    <Wrapper>
      <ImageWrapper>
        {image && (
          <GatsbyImage
            image={image}
            alt="The PLX-Benchtop, a revolutionary new approach to mechanical testing for applications in failure analysis, high throughpiut testign and addtive manufacturing"
          />
        )}
      </ImageWrapper>
      <Container>
        <Title>{title}</Title>
        {items.map((item) => (
          <Item key={item.title}>
            <Heading>{item.title}</Heading>
            <Description>{item.description}</Description>
          </Item>
        ))}
      </Container>
    </Wrapper>
  );
};

export default WorkWithUs;

const Wrapper = styled("div", {
  marginBottom: 100,
  "@md": {
    width: "90%",
    maxWidth: 1220,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "auto",
    padding: "200px 0",
  },
});
const Container = styled("div", {
  width: "90%",
  margin: "auto",
  maxWidth: 600,
  "@md": {
    maxWidth: 467,
    margin: 0,
  },
});
const ImageWrapper = styled("div", {
  maxWidth: 600,
  margin: "auto",
  "@md": {
    borderRadius: 8,
    overflow: "hidden",
    margin: 0,
    marginRight: 20,
  },
});
const Item = styled("div", {
  marginBottom: 24,
  "@md": {
    padding: "15px 22px",
    borderRadius: 12,
    position: "relative",
    "&:hover": {
      background: "rgba(235, 240, 247, 1)",
      "&:before": {
        content: "",
        width: 2,
        height: "80%",
        background: "$blue100",
        position: "absolute",
        left: 0,
        top: "50%",
        transform: "translateY(-50%)",
      },
    },
  },
});
const Title = styled("h2", {
  ...heading150,
  "@md": {
    ...heading300,
  },
});
const Heading = styled("h6", {
  ...heading100,
  color: "$blue100",
  margin: 0,
  "@md": {
    color: "$black100",
    marginBottom: 10,
  },
});
const Description = styled("p", {
  ...paragraphTextSmall,
  margin: 0,
});
